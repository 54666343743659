import React, { useState, useEffect, useRef } from 'react';
import { List, Button, Input } from 'antdv4';
import * as rest from 'common/rest'
const { TextArea } = Input

const ChatBox = () => {
  const [userInput, setUserInput] = useState('');
  const [chatLog, setChatLog] = useState([{"index": 0, "role": "system", "content": "你是一个优秀的英语老师。你将给学生解答各种英语学习相关问题。"}, {"index": 1, "role": "assistant", "content": "你好，我是AI助教，请问有什么我可以帮你的吗？"}]);
  const bottomRef = useRef(null);

  const handleUserInputChange = (e) => {
    setUserInput(e.target.value);
  };

  const handleChatSubmit = async () => {
    let log = [...chatLog, { role: 'user', content: userInput, index: chatLog.length }]
    // Add the user message to the chat log
    setChatLog(log);
    // Clear the input
    setUserInput('');

    const url = '/api/ai/pt'
    try {
      const res = await rest.post(url, { messages: log })
      if (res.code === 0) {
        setChatLog([...log, { role: 'assistant', content: res.data, index: chatLog.length }]);
      } else {
        console.log('AI Pt request failed: ', res)
      }
    } catch (err) {
      throw err
    }
  };

  useEffect(() => {
    // Scroll to the bottom every time chatLog changes
    bottomRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [chatLog]);

  const disabled = userInput.length === 0;
  const btnType = userInput.length === 0 ? 'ghost' : 'primary';
  const displayChatLog = chatLog.length > 21 ? chatLog.slice(-20) : chatLog.slice(1);

  return (
    <div className='ai-chatbox'>
      <div className="chat-content">
        <List
          dataSource={displayChatLog}
          renderItem={item => (
            <List.Item key={item.index}>
              <div className='message-container'>
                <div className='message-content-wrapper'>
                  <div className={item.role === 'user' ? 'self-message-content message-content' : 'assistant-message-content message-content'}>
                    <pre>
                      {item.content}
                    </pre>
                  </div>
                </div>
              </div>
            </List.Item>
          )}
        />
        <div ref={bottomRef} />
      </div>
      <div className="chat-input">
        <TextArea
          className="chat-content"
          rows={4}
          onChange={handleUserInputChange}
          value={userInput}
        />
        <Button
          disabled={disabled}
          type={btnType}
          onClick={handleChatSubmit}
        >
          提交
        </Button>
      </div>
    </div>
  );
};

export default ChatBox;
