import * as React from 'react'
import { Switch } from 'react-router-dom'
import MyLoadable from 'components/loadable'
import AuthorizedRoute from 'components/common/authorized_route'

const routes = [
  {
    path: 'dashboard',
    component: MyLoadable({
      loader: () => import('containers/school/dashboard.js')
    })
  },
  {
    path: 'my-homepage', // 我的主页
    component: MyLoadable({
      loader: () => import('containers/school/my_homepage')
    })
  },
  {
    path: 'transfer/transfer-list/:filter?', // 转案列表页面
    component: MyLoadable({
      loader: () => import('containers/school/transfer_list')
    })
  },
  {
    path: 'transfer/transfer-view/:id', // 转案详情
    perm: 'transfer-view',
    component: MyLoadable({
      loader: () => import('containers/school/transfer_detail')
    })
  },
  {
    path: 'transfer/pigai-authorization-manage', // 批改授权
    component: MyLoadable({
      loader: () => import('containers/school/pigai_authorization_manage')
    })
  },
  {
    path: 'transfer/student-list', // 学员列表
    component: MyLoadable({
      loader: () => import('containers/school/student_list')
    })
  },
  {
    path: 'transfer/student-archives', // 学员档案
    component: MyLoadable({
      loader: () => import('containers/school/student_archives')
    })
  },
  {
    path: 'transfer/student-score', // 学员成绩管理
    component: MyLoadable({
      loader: () => import('containers/school/student_score')
    })
  },
  {
    path: 'study/evaluation', // 测评管理
    isExact: true,
    component: MyLoadable({
      loader: () => import('containers/school/evaluation')
    })
  },
  {
    path: 'study/test-paper-generator', // AI组卷
    isExact: true,
    component: MyLoadable({
      loader: () => import('containers/school/test_paper_generator')
    })
  },
  {
    path: 'study/evaluation-create/:id', // 添加人工报告
    component: MyLoadable({
      loader: () => import('containers/school/report-detail')
    })
  }, // TODO: 如何实现同名前缀？
  {
    path: 'study/evaluation-view/:id', // 查看人工报告
    component: MyLoadable({
      loader: () => import('containers/school/report-detail')
    })
  },
  {
    path: 'study/online-student-task', // 学习任务(在线学习中心)
    component: MyLoadable({
      loader: () => import('containers/school/study-task')
    })
  },
  {
    path: 'study/teaching-plans', // 教学计划（线下，总部）
    component: MyLoadable({
      loader: () => import('containers/school/teaching-plans')
    })
  },
  {
    path: 'study/teaching-plan/:type/:id', // 教学计划(线下，总部)
    component: MyLoadable({
      loader: () => import('containers/school/teaching-plan-detail')
    })
  },
  {
    path: 'study/student-task', // 学习任务(线下学习中心)
    component: MyLoadable({
      loader: () => import('containers/school/day_task')
    })
  },
  {
    path: 'study/pt-daily', // 单独的pt日报集合模块
    component: MyLoadable({
      loader: () => import('containers/school/pt_daily_list')
    })
  },
  {
    path: 'study/pt-daily-view/:id?', // 查看PT日报
    component: MyLoadable({
      loader: () => import('containers/school/pt_daily_view')
    })
  },
  {
    path: 'study/pt-daily-single-view/:watch?', // 编辑PT日报
    component: MyLoadable({
      loader: () => import('containers/school/pt_daily')
    })
  },
  {
    path: 'study/pt-daily-edit/:watch?', // 编辑PT日报
    component: MyLoadable({
      loader: () => import('containers/school/pt_daily')
    })
  },
  {
    path: 'study/study-history', // 学习历史
    component: MyLoadable({
      loader: () => import('containers/school/study_history')
    })
  },
  {
    path: 'study/study-plan/:studentId/:studentName?', // 智能学习计划
    component: MyLoadable({
      loader: () => import('containers/school/study_plan')
    })
  },
  {
    path: 'study/weekly-list/:view?', // 周报列表
    component: MyLoadable({
      loader: () => import('containers/school/weekly_list')
    })
  },
  {
    path: 'study/weekly-view', // 普通周报的查看
    component: MyLoadable({
      loader: () => import('components/school/banke/banke_detail/report/weekly')
    })
  },
  {
    path: 'teaching/teachers/:filter?', // 教师列表
    component: MyLoadable({
      loader: () => import('containers/school/teacher_list')
    })
  },
  {
    path: 'teaching/teacher-create', // 创建教师
    component: MyLoadable({
      loader: () => import('containers/school/teacher_detail')
    })
  },
  {
    path: 'teaching/teacher-update/:id', // 编辑教师
    component: MyLoadable({
      loader: () => import('containers/school/teacher_detail')
    })
  },
  {
    path: 'teaching/teacher-view/:id', // 查看教师
    component: MyLoadable({
      loader: () => import('containers/school/teacher_detail')
    })
  },
  {
    path: 'teaching/preview-course', // 课程预览
    component: MyLoadable({
      loader: () => import('containers/school/preview_course')
    })
  },
  {
    path: 'teaching/performance', // 绩效系数
    component: MyLoadable({
      loader: () => import('containers/school/performance')
    })
  },
  {
    path: 'teaching/teacher-time/:id?', // 教师时间管理
    component: MyLoadable({
      loader: () => import('containers/school/teacher_calendar')
    })
  },
  {
    path: 'teaching/template/:filter?', // 模板列表
    component: MyLoadable({
      loader: () => import('containers/school/template_list')
    })
  },
  {
    path: 'teaching/template-view/:id', // 查看模板
    component: MyLoadable({
      loader: () => import('containers/school/template_detail')
    })
  },
  {
    path: 'teaching/template-update/:id', // 编辑模板
    component: MyLoadable({
      loader: () => import('containers/school/template_detail')
    })
  },
  {
    path: 'teaching/documents/:filter?', // 资料列表
    component: MyLoadable({
      loader: () => import('containers/school/document_list')
    })
  },
  {
    path: 'teaching/questions/:filter?', // 答题列表
    component: MyLoadable({
      loader: () => import('containers/school/question_list')
    })
  },
  {
    path: 'school-manage/structures/:filter?', // 组织机构管理
    component: MyLoadable({
      loader: () => import('containers/school/structure_list')
    })
  },
  {
    path: 'school-manage/staffs/:filter?', // 员工列表
    component: MyLoadable({
      loader: () => import('containers/school/staff_list')
    })
  },
  {
    path: 'school-manage/staff-view/:id', // 查看单个员工
    component: MyLoadable({
      loader: () => import('containers/school/staff')
    })
  },
  {
    path: 'school-manage/staff-create', // 创建员工
    component: MyLoadable({
      loader: () => import('containers/school/staff')
    })
  },
  {
    path: 'school-manage/staff-edit/:id', // 编辑员工
    component: MyLoadable({
      loader: () => import('containers/school/staff')
    })
  },
  {
    path: 'school-manage/classrooms/:filter?', // 教室列表
    component: MyLoadable({
      loader: () => import('containers/school/classroom_list')
    })
  },
  {
    path: 'school-manage/classroom-create', // 创建教室
    component: MyLoadable({
      loader: () => import('containers/school/classroom')
    })
  },
  {
    path: 'school-manage/classroom-edit/:id', // 编辑教室
    component: MyLoadable({
      loader: () => import('containers/school/classroom')
    })
  },
  {
    path: 'school-manage/classroom-view/:id', // 查看教室
    component: MyLoadable({
      loader: () => import('containers/school/classroom')
    })
  },
  {
    path: 'school-manage/studios/:filter?', // 直播间列表
    component: MyLoadable({
      loader: () => import('containers/school/studio_list')
    })
  },
  {
    path: 'statistics/student-course-hours', // 学员课时统计
    component: MyLoadable({
      loader: () => import('containers/school/student_course_hours')
    })
  },
  {
    path: 'statistics/teacher-course-hours', // 教师课时统计
    component: MyLoadable({
      loader: () => import('containers/school/teacher_course_hours')
    })
  },
  {
    path: 'statistics/online-teacher-course-hours', // 教师课时统计（在线站点专用）
    component: MyLoadable({
      loader: () => import('containers/school/teacher_course_hours')
    })
  },
  {
    path: 'statistics/teacher-reward', // 教师课时统计
    component: MyLoadable({
      loader: () => import('containers/school/teacher_reward')
    })
  },
  {
    path: 'statistics/expended-teacher-course-hours', // 课消统计
    component: MyLoadable({
      loader: () => import('containers/school/expended_teacher_course_hours')
    })
  },
  {
    path: 'statistics/student-sign', // 签到管理
    component: MyLoadable({
      loader: () => import('containers/school/student_sign')
    })
  },
  {
    path: 'statistics/study-task-statistics', // 学习任务统计
    component: MyLoadable({
      loader: () => import('containers/school/study_task_statistics')
    })
  },
  {
    path: 'statistics/mokao-statistics', // 学习任务时长统计
    component: MyLoadable({
      loader: () => import('containers/school/mokao_statistics')
    })
  },
  {
    path: 'schedule/teaching-reqs/:filter?', // 排课申请列表
    component: MyLoadable({
      loader: () => import('containers/school/teaching_req_list')
    })
  },
  {
    path: 'schedule/teaching-req-create/:id?', // 添加排课申请
    perm: 'teachingReq-create',
    component: MyLoadable({
      loader: () => import('containers/school/teaching_req_detail')
    })
  },
  {
    path: 'schedule/teaching-req-view/:id', // 查看排课申请
    perm: 'teachingReq-view',
    component: MyLoadable({
      loader: () => import('containers/school/teaching_req_detail')
    })
  },
  {
    path: 'schedule/changing-reqs/:filter?', // 调课申请列表
    component: MyLoadable({
      loader: () => import('containers/school/changing_req_list')
    })
  },
  {
    path: 'schedule/changing-req-create/:id?', // 添加调课申请
    perm: 'changingReq-create',
    component: MyLoadable({
      loader: () => import('containers/school/changing_req')
    })
  },
  {
    path: 'schedule/changing-req-view/:id', // 查看调课申请
    component: MyLoadable({
      loader: () => import('containers/school/changing_req_detail')
    })
  },
  {
    path: 'schedule/new-group-course/:filter?', // 班课列表(旧)
    component: MyLoadable({
      loader: () => import('containers/school/group_course_list')
    })
  },
  {
    path: 'schedule/new-group-course-detail/:id', // 班课详情(旧)
    component: MyLoadable({
      loader: () => import('containers/school/group_course_detail')
    })
  },
  {
    path: 'schedule/new-group-course-edit/:id', // 编辑班课(旧)
    component: MyLoadable({
      loader: () => import('containers/school/group_course_detail')
    })
  },
  {
    path: 'schedule/banke/:view?/:subView?', // 班课管理
    component: MyLoadable({
      loader: () => import('containers/school/banke')
    })
  },
  {
    path: 'schedule/evaluation-detail/:bankeId/:bankeTaskId', //测评管理详情
    component: MyLoadable({
      loader: () => import('containers/school/evaluation_detail')
    })
  },
  {
    path: 'schedule/banke-weekly/:view/:id', // 班课周报
    component: MyLoadable({
      loader: () => import('components/school/banke/banke_detail/report/weekly')
    })
  },
  {
    path: 'schedule/banke-daily/:id', // 班课日报
    component: MyLoadable({
      loader: () => import('components/school/banke/banke_detail/report/daily')
    })
  },
  {
    path: 'schedule/banke-view/:view/:id', // 查看班课
    component: MyLoadable({
      loader: () => import('containers/school/banke_detail')
    })
  },
  {
    path: 'schedule/trial-course-requests', // 试听课列表
    component: MyLoadable({
      loader: () => import('containers/school/trial_course_list')
    })
  },
  {
    path: 'schedule/trial-course-request/:id', // 试听课详情
    component: MyLoadable({
      loader: () => import('containers/school/trial_course_view')
    })
  },
  {
    path: 'schedule/student-schedule/:defaultId?', // 学生课表
    component: MyLoadable({
      loader: () => import('containers/school/student_schedule')
    })
  },
  {
    path: 'schedule/teacher-schedule/:defaultId?', // 教师课表
    component: MyLoadable({
      loader: () => import('containers/school/teacher_schedule')
    })
  },
  {
    path: 'schedule/my-schedule', // 我的课表（教师课表）
    component: MyLoadable({
      loader: () => import('containers/school/teacher_schedule')
    })
  },
  {
    path: 'schedule/classroom-usage/:filter?', // 教室课表
    component: MyLoadable({
      loader: () => import('containers/school/classroom_usage')
    })
  },
  {
    path: 'schedule/teacher-schedule-overview-school/:filter?', // 教师饱和度
    component: MyLoadable({
      loader: () => import('containers/school/teacher_schedule_overview')
    })
  },
  {
    path: 'schedule/classroom-schedule-overview-school/:filter?', // 教室饱和度
    component: MyLoadable({
      loader: () => import('containers/school/classroom_schedule_overview')
    })
  },
  {
    path: 'messages/my-messages', // 我的消息
    component: MyLoadable({
      loader: () => import('containers/school/my_messages')
    })
  },
  {
    path: 'teaching-work/arrangement/:filter?', // 排课申请列表
    component: MyLoadable({
      loader: () => import('containers/school/teaching_req_list')
    })
  },
  {
    path: 'teaching-work/changing/:filter?', // 调课申请列表
    component: MyLoadable({
      loader: () => import('containers/school/changing_req_list')
    })
  },
  {
    path: 'teaching-work/arrange/:id', // 排课
    perm: 'arrangement-arrange',
    component: MyLoadable({
      loader: () => import('containers/school/teaching_arranging')
    })
  },
  {
    path: 'teaching-work/change/:id', // 调课
    perm: 'changing-arrange',
    component: MyLoadable({
      loader: () => import('containers/school/teaching_arranging')
    })
  },
  {
    path: 'teaching-work/new-group-course/:filter?', // 旧班课列表
    component: MyLoadable({
      loader: () => import('containers/school/group_course_list')
    })
  },
  {
    path: 'teaching-work/banke/:view?/:subView?', // 班课管理
    component: MyLoadable({
      loader: () => import('containers/school/banke')
    })
  },
  {
    path: 'teaching-work/banke-view/:view/:id', // 班课查看
    component: MyLoadable({
      loader: () => import('containers/school/banke_detail')
    })
  },
  {
    path: 'teaching-work/banke-solving/:view/:id', // 班课排课页面
    component: MyLoadable({
      loader: () => import('containers/school/group_course_detail') // TODO: 先叫做solving
    })
  },
  {
    path: 'teaching-work/trial-course-requests', // 试听课列表
    component: MyLoadable({
      loader: () => import('containers/school/trial_course_list')
    })
  },
  {
    path: 'teaching-work/exchange-course', // 退换课列表
    component: MyLoadable({
      loader: () => import('containers/school/exchange_course_manage')
    })
  },
  {
    path: 'teaching-work/student-schedule/:defaultId?', // 学生课表
    component: MyLoadable({
      loader: () => import('containers/school/student_schedule')
    })
  },
  {
    path: 'teaching-work/teacher-schedule/:defaultId?', // 教师课表
    component: MyLoadable({
      loader: () => import('containers/school/teacher_schedule')
    })
  },
  {
    path: 'teaching-work/classroom-usage/:filter?', // 教室课表
    component: MyLoadable({
      loader: () => import('containers/school/classroom_usage')
    })
  },
  {
    path: 'teaching-work/teaching-req-view/:id', // 排课申请
    component: MyLoadable({
      loader: () => import('containers/school/teaching_req_detail')
    })
  },
  {
    path: 'teaching-work/teacher-schedule-overview/:filter?', // 教师饱和度统计
    component: MyLoadable({
      loader: () => import('containers/school/teacher_schedule_overview')
    })
  },
  {
    path: 'teaching-work/classroom-schedule-overview/:filter?', // 教室饱和度统计
    component: MyLoadable({
      loader: () => import('containers/school/classroom_schedule_overview')
    })
  },
  {
    path: 'teaching-work/quality-inspection-management', // 教室饱和度统计
    component: MyLoadable({
      loader: () => import('containers/school/quality_inspection_management')
    })
  },
  {
    path: 'school-manage/goods-management', // 商品管理
    component: MyLoadable({
      loader: () => import('containers/school/goods_management')
    })
  }
]

export default class SchoolRoutes extends React.Component<any, any> {
  render () {
    const { match } = this.props
    // path中的可选参数不再是(/:xxx)形式，而是直接在参数后加一个问号
    return <Switch>
      {
        routes.map(route => {
          return <AuthorizedRoute
            component={route.component}
            isExact={route.isExact}
            path={`${match.path}/${route.path}`}
            key={route.path}
            perm={route.perm}
          />
        })
      }
    </Switch>
  }
}
